import React, { useState, useEffect, useRef } from "react"
import i18n from "i18next"
import columnImageRight from "../images/products/BannerTalent.png"

// Layout
import Layout from "../components/layout"
import SEO from "../components/seo"
import { withNamespaces } from "react-i18next"

import teamEs from "./../components/ourDNA/serviceOurTeam/teamEs"
import teamEn from "./../components/ourDNA/serviceOurTeam/teamEn"

import BannerTalentATS from "../components/Products/BannerTalentATS"
import AboutThisProduct from "../components/Products/AboutThisProduct"
import ProductsFeatures from "../components/Products/ProductsFeatures"
import TargetCounts from "../components/Products/TargetCounts"
import VideoTalent from "../components/Products/VideoTalent"

import icon36 from "../images/products/icon36.png"
import icon57 from "../images/products/icon57.png"
import icon68 from "../images/products/icon68-1.png"
import icon192 from "../images/products/icon192.png"

const TalentATS = () => {
  const [descriptionTitleBanner, setDescriptionTitleBanner] = useState([])
  const [youtubeVideoId, setYoutubeVideoId] = useState("")
  const [team, setTeam] = useState(teamEs)
  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView()

  const targetCountArr = [
    {
      image: icon192,
      texto: i18n.t("TalentATS.icon192"),
    },
    {
      image: icon68,
      texto: i18n.t("TalentATS.icon68"),
    },
    {
      image: icon57,
      texto: i18n.t("TalentATS.icon57"),
    },
    {
      image: icon36,
      texto: i18n.t("TalentATS.icon36"),
    },
  ]

  const attributesMainTitle = {
    id: "TalentATS",
    title: i18n.t("pageourdna.ourteam"),
    styles: {
      bulletColor: "dark-blue",
      textColor: "light-blue",
      fontSize: "44",
      fontSizeMobile: "36",
      alignMobile: "left",
      alignDesktop: "left",
      marginL: "20",
    },
  }
  useEffect(() => {
    if (i18n.language === "en") {
      setTeam(teamEn)
      setYoutubeVideoId("GcDt4A3Xbn4")
      setDescriptionTitleBanner([
        <div className="flex flex-col txt-our-adn txt-our-adn-movil">
          <h3 className="inline-block desktop:w-10/12 desktop:text-left mobile:text-left mb-4 desktop:mx-24">
            <br></br>
            <br></br>
            <br></br>
            <span className="textOurExperience2 flex">
              <p className="subtitleTextTalent">E</p>
              <p className="subtitleTextTalentX">x</p>
              <p className="subtitleTextTalent">ceptional products</p>
            </span>
            <span className="textTalentATS">
              <p className="colorTextExperience2">Talent</p>
              <p className="colorTextExperience2">ATS</p>
            </span>
            <br></br>
            <br></br>
            <br></br>
          </h3>
        </div>,
      ])
    } else {
      setTeam(teamEs)
      setYoutubeVideoId("EGpJEq_JRPA")
      setDescriptionTitleBanner([
        <div className="flex flex-col txt-our-adn txt-our-adn-movil">
          <h3 className="inline-block desktop:w-10/12 desktop:text-left mobile:text-left mb-4 desktop:mx-24">
            <br></br>
            <br></br>
            <br></br>
            <span className="textOurExperience2 flex">
              <p className="subtitleTextTalent">Productos E</p>
              <p className="subtitleTextTalentX">x</p>
              <p className="subtitleTextTalent">cepcionales</p>
            </span>
            <span className="textTalentATS">
              <p className="colorTextExperience2">Talento</p>
              <p className="colorTextExperience2">ATS</p>
            </span>
            <br></br>
            <br></br>
            <br></br>
          </h3>
        </div>,
      ])
    }
  }, [i18n.language])

  return (
    <Layout>
      <SEO
        title={"Talent ATS | Exsis Digital Angels"}
        description={"Nearshore Agile Teams | Specialists in Tech Talent"}
      />

      <BannerTalentATS
        bannerImageURL={columnImageRight}
        bannerImageContent={descriptionTitleBanner}
      ></BannerTalentATS>

      <AboutThisProduct />

      <ProductsFeatures />

      <TargetCounts arrayitems={targetCountArr} div="whyusdiv" />

      {/*<VideoTalent />*/}
    </Layout>
  )
}

export default withNamespaces()(TalentATS)
