import React, { useContext } from "react"
import i18n from "i18next"
import { LoadingContext } from "../../components/context/LoadingContext"

import image1 from "../../images/products/mockup1.png"
import image2 from "../../images/products/mockup2.png"
import image3 from "../../images/products/mockup3.png"

const ProductsFeatures = ({}) => {
  const { windowWidth } = useContext(LoadingContext)

  return (
    <div className="mobile:w-full desktop:pl-4 desktop:pr-4 mobile:pl-4 mobile:pr-4 desktop:flip-card bannerCertification">
      {windowWidth >= 1025 ? (
        <div className="flip-card-inner2  mt-24 mb-24">
          <div className="mobile:w-full mobile:mb-4 landscape:mb-0 landscape:w-full desktop:pr-4 mobile:pr-16">
            <p className="titleCompanies">{i18n.t("ProductsFeatures.title")}</p>
          </div>
          <div>
            <div className="flex mx-12 mt-12 justifyStyle">
              <div className="">
                <img src={image1} alt="...." className="imgSize mt-6"/>

                <div>
                  <p className="titleProductsFeature m-4">
                    {i18n.t("ProductsFeatures.subtitle1")}
                  </p>
                  {windowWidth >= 1025 && windowWidth <= 1700 ? (
                    <p className="textProductsFeature m-4">
                      {i18n.t("ProductsFeatures.text1")}
                    </p>
                  ) : (
                    <p className="widthResponsive textProductsFeature m-4">
                      {i18n.t("ProductsFeatures.text1")}
                    </p>
                  )}
                </div>
              </div>
              <div className="bigdesktop:mt-6">
                <img src={image2} alt="...." className="imgSize" />

                <div className="">
                  <p className="titleProductsFeature m-4">
                    {i18n.t("ProductsFeatures.subtitle2")}
                  </p>
                  {windowWidth >= 1025 && windowWidth <= 1700 ? (
                    <p className="textProductsFeature m-4">
                      {i18n.t("ProductsFeatures.text2")}
                    </p>
                  ) : (
                    <p className="textProductsFeature widthResponsive m-4">
                      {i18n.t("ProductsFeatures.text2")}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className=" ">
              <img src={image3} alt="...." className="imgSize mx-auto mt-8" />

              {windowWidth >= 1025 && windowWidth <= 1700 ? (
                <div className="mx-96">
                  <p className="titleProductsFeature m-4">
                    {i18n.t("ProductsFeatures.subtitle3")}
                  </p>
                  <p className="textProductsFeature m-4">
                    {i18n.t("ProductsFeatures.text3")}
                  </p>
                </div>
              ) : (
                <div className="contentResponsiveProduts">
                  <p className="titleProductsFeature m-4">
                    {i18n.t("ProductsFeatures.subtitle3")}
                  </p>
                  <p className="textProductsFeature m-4">
                    {i18n.t("ProductsFeatures.text3")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="  mt-24 mb-24">
          <div className="mobile:w-full mobile:mb-4 landscape:mb-0 landscape:w-full desktop:pr-4 ">
            <p className="titleCompanies">{i18n.t("ProductsFeatures.title")}</p>
          </div>

          <div>
            <div className="  mt-12 ">
              <div className=" ">
                <img src={image1} alt="...." className="" />

                <div>
                  <p className="titleProductsFeature m-4">
                    {i18n.t("ProductsFeatures.subtitle1")}
                  </p>
                  <p className="textProductsFeature m-4">
                    {i18n.t("ProductsFeatures.text1")}
                  </p>
                </div>
              </div>
              <div className=" ">
                <img src={image2} alt="...." className="" />

                <div>
                  <p className="titleProductsFeature m-4">
                    {i18n.t("ProductsFeatures.subtitle2")}
                  </p>
                  <p className="textProductsFeature m-4">
                    {i18n.t("ProductsFeatures.text2")}
                  </p>
                </div>
              </div>
            </div>

            <div className=" ">
              <img src={image3} alt="...." className="" />

              <div>
                <p className="titleProductsFeature m-4">
                  {i18n.t("ProductsFeatures.subtitle3")}
                </p>
                <p className="textProductsFeature m-4">
                  {i18n.t("ProductsFeatures.text3")}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProductsFeatures
